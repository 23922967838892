angular
    .module('MyHippoProducer.Services')
    .factory('GraphqlService', function ($http, $cookies) {
        function fetch(query) {
            const agentJWT = $cookies.get('hippo-agent-jwt');
            return $http.post(`${window.BFF_HOST}/graphql`, JSON.stringify({ query: query }), {
                withCredentials: false,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${agentJWT}`,
                },
            });
        }

        const graphqlService = {
            fetch,
        };
        return graphqlService;
    });
